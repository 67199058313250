<template>
  <div class="container-editor-email">
    <div class="container-bar-editor-email my-2 rounded">
      <h1>{{this.$t("custom_email.title_view_new")}}</h1>
      <button
        class="bg-yellow-500 hover:bg-yellow-100 text-gray-800 font-semibold py-2 px-4 border border-yellow-400 rounded shadow"
        @click="saveDesign"
        :disabled="isLoading"
      >{{this.$t("custom_email.btn_create")}}</button>
    </div>
    <EmailEditor ref="emailEditor" @load="editorLoaded" />
    <vue-form
      ref="EmailForm"
      :popupActive="popupActive"
      @submitForm="getDataForm"
      @closePopup="closeModal"
    ></vue-form>
  </div>
</template>
<script>

import { EmailEditor } from "vue-email-editor";
import HTTP from "@/http-common";
import FieldsCustomEmail from "@/fields/email/fieldsEmails";
import EmailDefault from "../../fields/email/defaultEmail"
import EmailForm from "@/components/email/Form";

const NAME = "EmailNew";

export default {
  name: NAME,
  components: {
    "email-editor": EmailEditor,
    "vue-form": EmailForm,
  },
  data() {
    return {
      isLoading: true,
      popupActive: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    editorLoaded() {
      this.isLoading = false;
      this.$refs.emailEditor.editor.loadDesign(EmailDefault)
      // this.$refs.emailEditor.editor.setMergeTagsConfig(
      //   FieldsCustomEmail.mergeTagsConfig
      // );
      this.$refs.emailEditor.editor.setMergeTags(FieldsCustomEmail.mergeTags);
      this.$refs.emailEditor.editor.registerCallback(
        "image",
        this.callbackImage
      );
    },
    async callbackImage(file, done) {
      let data = new FormData();
      let url;
      data.append("data", file.attachments[0]);
      try {
        let response = await HTTP.post("v1/custom-emails/image", data);
        url = response.data.url;
        done({ progress: 100, url });
      } catch (error) {
        console.log(error);
        done({ progress: 100, url: "ERROR SERVER IN URL OF THE FILE" });
      }
    },
    async getDataForm(formData) {
      this.closeModal();
      try {
        await this.sendDesign(
          formData.name,
          formData.company_id,
          formData.status_id,
          formData.newsSelected
        );
        await this.$swal({
          position: "center",
          type: "success",
          title: this.$t("custom_email.title_success_template"),
          confirmButtonColor: "#FDD402",
          allowOutsideClick: false,
          timer: 3000,
        });
        this.$router.push({ name: "index-email" });
      } catch (error) {
        return this.$swal({
          position: "center",
          type: "warning",
          confirmButtonColor: "#FDD402",
          title: "Oops...",
          text: error.response.data.error.message,
        });
      }
    },
    closeModal() {
      this.popupActive = false;
    },
    async saveDesign() {
      this.popupActive = true;
    },
    sendDesign(name, company_id, status_id,newsSelected) {
      return new Promise((resolve, reject) => {
        this.$refs.emailEditor.editor.exportHtml(async (design) => {
          try {
            await HTTP.post("v1/custom-emails", {
              name,
              company_id,
              status_id,
              jsonModel: { body: design.design.body },
              htmlModel: design.html,
              newsSelected
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    async saveDesignSimple() {
      await this.$swal({
        position: "center",
        input: "text",
        title: this.$t("custom_email.title_save_template"),
        text: this.$t("custom_email.message_save_template"),
        confirmButtonColor: "#FDD402",
        showLoaderOnConfirm: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.$t("save"),
        preConfirm: async (name) => {
          if (name) {
            this.name = name;
          }
        },
      });
      if (this.name !== "") {
        try {
          await this.sendDesign();
          await this.$swal({
            position: "center",
            type: "success",
            title: this.$t("custom_email.title_success_template"),
            confirmButtonColor: "#FDD402",
            allowOutsideClick: false,
            timer: 3000,
          });
          this.$emit("success");
        } catch (error) {
          return this.$swal({
            position: "center",
            type: "warning",
            confirmButtonColor: "#FDD402",
            title: "Oops...",
            text: error.response.data.error.message,
          });
        }
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.container-editor-email {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 550px;
}

.container-bar-editor-email {
  background-color: #333;
  flex: 1;
  padding: 10px;
  display: flex;
  max-height: 60px;
}

.container-bar-editor-email h1 {
  flex: 1;
  text-align: left;
  color: #ffffff;
}

.container-bar-editor-email button {
  flex: 1;
  margin-left: 10px;
  max-width: 150px;
  cursor: pointer;
}
</style>