<template lang="pug">
email-new
</template>

<script>
import EmailNew from "@/components/email/New.vue";
import { validatePermission } from "@/Ability";

const COMPONENT_NAME = "ViewNewEmail";

export default {
  name: COMPONENT_NAME,
  components: {
    "email-new": EmailNew,
  },
  methods: {},
  created() {
    validatePermission(this, "create", "email", "New");
  },
};
</script>